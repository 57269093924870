<template>
    <div class="container">
        <div class="d-grid">
            <div class="row">
                <div class="col-4">
                    <h1 class="display-1" style="color: white;">{{ showHours ? offsetHours : "" }}{{ showHours ? ":" : "" }}{{ minutesToDisplay }}:{{ secondsToDisplay }}</h1>
                </div>
                <div class="col-8">
                    <div class="color-box" :class="colorClass"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'TimerComponent',
    props: {
        minutes: {
            required: true,
            type: Number
        },
        seconds: {
            required: true,
            type: Number
        },
        offsetMinutes : {
            required: true,
            type: Number
        },
        offsetHours: {
            required: false,
            type: Number
        },
        showHours: {
            required: true,
            type: Boolean
        },
        colorClass: {
            required: true,
            type: String
        }
    },
    computed: {
        minutesToDisplay() {
            return this.prependZeroIfNecessary(this.minutes + this.offsetMinutes);
        },
        secondsToDisplay() {
            return this.prependZeroIfNecessary(this.seconds);
        }
    },
    methods: {
        prependZeroIfNecessary(number) {
            return (number).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
        }
    }
}
</script>

<style>
.display {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>