<template>
  <div class="container" style="height: 100vh; max-width: 1800px;">
    <div class="d-grid gap-5">
      <div style="margin-top: 150px;">
        <div class="row">
          <div class="col" style="float: right;">
            <div class="color-box" style="max-width: 400px;" :class="timeColors[8]"></div>
          </div>
        </div>
      </div>
      <div style="margin-top:30px;">
        <div class="row">
          <div class="col">
            <TimerComponent :minutes="minutes" :seconds="seconds" :offsetMinutes="0" :showHours="false" :colorClass="timeColors[0]"/>
          </div>
          <div class="col">
            <TimerComponent :minutes="minutes" :seconds="seconds" :offsetMinutes="0" :showHours="true" :offsetHours="1" :colorClass="timeColors[4]"/> 
          </div>
        </div>
        <div class="row">
          <div class="col">
            <TimerComponent :minutes="minutes" :seconds="seconds" :offsetMinutes="15" :showHours="false" :colorClass="timeColors[1]"/> 
          </div>
          <div class="col">
            <TimerComponent :minutes="minutes" :seconds="seconds" :offsetMinutes="15" :showHours="true" :offsetHours="1" :colorClass="timeColors[5]"/> 
          </div>
        </div>
        <div class="row">
          <div class="col">
            <TimerComponent :minutes="minutes" :seconds="seconds" :offsetMinutes="30" :showHours="false" :colorClass="timeColors[2]"/> 
          </div>
          <div class="col">
            <TimerComponent :minutes="minutes" :seconds="seconds" :offsetMinutes="30" :showHours="true" :offsetHours="1" :colorClass="timeColors[6]"/> 
          </div>
        </div>
        <div class="row">
          <div class="col">
            <TimerComponent :minutes="minutes" :seconds="seconds" :offsetMinutes="45" :showHours="false" :colorClass="timeColors[3]"/>
          </div>
          <div class="col">
            <TimerComponent :minutes="minutes" :seconds="seconds" :offsetMinutes="45" :showHours="true" :offsetHours="1" :colorClass="timeColors[7]"/> 
          </div>
        </div>  
      </div>
    </div>
  </div>
  <audio src="./assets/countdown.mp3"></audio>
</template>

<script>
import TimerComponent from './components/TimerComponent.vue'
import CountDownSound from './assets/countdown.mp3'

export default {
  name: 'App',
  components: {
    TimerComponent
  },
  data() {
        return {
            minutes: 15,
            seconds: 0,
            timeColors: [
              'time-block-1', 'time-block-2', 'time-block-3', 'time-block-4', 'time-block-5', 'time-block-6', 'time-block-7',
              'time-block-8', 'time-block-9', 'time-block-10', 'time-block-11', 'time-block-12', 'time-block-13', 'time-block-14',
              'time-block-15', 'time-block-16', 'time-block-17', 'time-block-18', 'time-block-19', 'time-block-20', 'time-block-21', 
              'time-block-22', 'time-block-1', 'time-block-2', 'time-block-3', 'time-block-4', 'time-block-5', 'time-block-6', 'time-block-7',
              'time-block-8', 'time-block-9', 'time-block-10', 'time-block-11', 'time-block-12', 'time-block-13', 'time-block-14',
              'time-block-15', 'time-block-16', 'time-block-17', 'time-block-18', 'time-block-19', 'time-block-20', 'time-block-21', 
              'time-block-22', 'time-block-1', 'time-block-2', 'time-block-3', 'time-block-4', 'time-block-5', 'time-block-6', 'time-block-7',
              'time-block-8', 'time-block-9', 'time-block-10', 'time-block-11', 'time-block-12', 'time-block-13', 'time-block-14',
              'time-block-15', 'time-block-16', 'time-block-17', 'time-block-18', 'time-block-19', 'time-block-20', 'time-block-21', 
              'time-block-22', 'time-block-1', 'time-block-2', 'time-block-3', 'time-block-4', 'time-block-5', 'time-block-6', 'time-block-7',
              'time-block-8', 'time-block-9', 'time-block-10', 'time-block-11', 'time-block-12', 'time-block-13', 'time-block-14',
              'time-block-15', 'time-block-16', 'time-block-17', 'time-block-18', 'time-block-19', 'time-block-20', 'time-block-21', 
              'time-block-22', 'time-block-1', 'time-block-2', 'time-block-3', 'time-block-4', 'time-block-5', 'time-block-6', 'time-block-7',
              'time-block-8']
        }
    },
    methods: {
        startTimer() {
            this.timerInterval = setInterval(this.countDown, 1000);
        },
        stopTimer() {
            clearInterval(this.timerInterval)
        },
        countDown() {
            if (this.seconds === 4 && this.minutes === 0) {
              this.playSound();
            }
            if (this.seconds > 0) {
                this.seconds--;
            } else {
                if (this.minutes === 0) {
                    this.minutes = 14;
                    this.updateTimer(null);
                    this.shiftColorClasses();
                } else {
                    this.minutes--;
                }
                this.seconds = 59;
            }
        },
        playSound() {
          var audio = new Audio(CountDownSound);
          audio.play();
        },
        orderColorClasses(date) {
          let periodsPassed = (date.getHours() * 4) + Math.floor(date.getMinutes()/15);
          for (let i = 0; i < periodsPassed - 7; i++) {
            this.shiftColorClasses();
          }
        },
        shiftColorClasses() {
          this.timeColors.push(this.timeColors.shift());
        },
        updateTimer(dateInput) {
          
          let date = dateInput != null ? dateInput : new Date();

          let minutes = date.getMinutes();

          if (minutes > 44) {
            this.minutes =  15 - (minutes - 44);
          } else if (minutes > 29) {
            this.minutes = 15 - (minutes - 29);
          } else if (minutes > 14) {
            this.minutes = 15 - (minutes - 14);
          } else {
            this.minutes = 15 - minutes - 1;
          }

          this.seconds = 60 - date.getSeconds();
        }
    },
    mounted() {
        const date = new Date();

        this.updateTimer(date);

        this.startTimer();
        this.orderColorClasses(date);
    },
    beforeUnmount() {
        this.stopTimer();
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url('./assets/AchtergrondCountdown.png');
}

.color-box {
    margin: 1em;
    height: 70px;
    width: 500px;
    float: right;
    outline-style: solid;
    outline-color: black;
}

.content {
  margin-top: 120px;
}

.time-block-1 {
    background-color: #FCE700;
}

.time-block-2 {
    background: linear-gradient(to bottom, #FCE700 33%, black 33%, black 66%, #FCE700 66%);
}

.time-block-3 {
    background-color: #35FF34;
}

.time-block-4 {
    background: linear-gradient(to bottom, #35FF34 33%, black 33%, black 66%, #35FF34 66%);
}

.time-block-5 {
  background-color: #00953B;
}

.time-block-6 {
  background: linear-gradient(to bottom, #00953B 33%, black 33%, black 66%, #00953B 66%);
}

.time-block-7 {
  background-color: #92BED9;
}

.time-block-8 {
  background: linear-gradient(to bottom, #92BED9 33%, black 33%, black 66%, #92BED9 66%);
}

.time-block-9 {
  background-color: #005AAE;
}

.time-block-10 {
  background: linear-gradient(to bottom, #005AAE 33%, black 33%, black 66%, #005AAE 66%);
}

.time-block-11 {
  background-color: #510E81;
}

.time-block-12 {
  background: linear-gradient(to bottom, #510E81 33%, black 33%, black 66%, #510E81 66%);
}

.time-block-13 {
  background-color: #E6488C;
}

.time-block-14 {
  background: linear-gradient(to bottom, #E6488C 33%, black 33%, black 66%, #E6488C 66%);
}

.time-block-15 {
  background-color: #E32533;
}

.time-block-16 {
  background: linear-gradient(to bottom, #E32533 33%, black 33%, black 66%, #E32533 66%);
}

.time-block-17 {
  background-color: #FD721C;
}

.time-block-18 {
  background: linear-gradient(to bottom, #FD721C 33%, black 33%, black 66%, #FD721C 66%);
}

.time-block-19 {
  background-color: #808080;
}

.time-block-20 {
  background: linear-gradient(to bottom, #808080 33%, black 33%, black 66%, #808080 66%);
}

.time-block-21 {
  background-color: #B0A560;
}

.time-block-22 {
  background: linear-gradient(to bottom, #B0A560 33%, black 33%, black 66%, #B0A560 66%);
}
</style>
